import React, { useEffect } from 'react'

import { useLocation } from "react-router"

const useQuery = () => new URLSearchParams(useLocation().search)

const Redirector = () => {
    const query = useQuery()
    useEffect(() => {
        let url = new URL(decodeURI(query.get('state')))
        query.delete('state')
        query.forEach((value, key) => url.searchParams.set(key, value))
        window.location.replace(url.toString())
    })
    return <h1 className="text-2xl font-bold text-center my-auto">Redirecting...</h1>
}

export default Redirector