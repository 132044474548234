import React from 'react';
import './App.scss';

import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import Redirector from './components/Redirector';

const apps = [
  {
    name: 'Power Widgets',
    description: 'Coming Soon',
    icon: 'powerwidgets-icon.png',
    link: 'https://apps.apple.com/us/app/power-widgets/id1545771094'
  },
  {
    name: 'Guess My Search',
    description: 'Family Feud for Google searches',
    icon: 'gms-icon.png',
    link: 'https://apps.apple.com/us/app/guess-my-search/id1144264060'
  },
  {
    name: 'Homepage for Reddit',
    description: 'FOSS cross-platform Reddit client',
    icon: 'homepage-icon.png',
    link: 'https://github.com/carson-katri/reddit-swiftui'
  },
]

function App() {
  return <Router>
    <Route exact path="/">
      <div className="flex flex-col h-screen overflow-y-scroll max-w-3xl mx-auto text-center">
        <div className="mt-8 flex-grow">
          <div className="flex flex-row justify-center align-baseline">
            <img src="logo.png" className="w-16 h-16 md:w-32 md:h-32 mr-4" alt="Build Passed logo" />
            <h1 className="text-4xl md:text-8xl font-bold mt-3 md:mt-4">Build Passed</h1>
          </div>
          <div className="flex flex-col justify-center w-max mx-auto mt-16 space-y-8">
            {apps.map(app => <a href={app.link}>
              <div className="flex flex-row">
                <img src={app.icon} className="rounded-xl w-16 h-16" alt={`Icon for ${app.name}`} />
                <div className="flex flex-col text-left ml-4">
                  <p className="text-2xl md:text-3xl font-bold">{app.name}</p>
                  <p className="text-sm md:text-base italic">{app.description}</p>
                </div>
              </div>
            </a>)}
          </div>
        </div>
        <footer className="flex-shrink mb-8 flex flex-row underline justify-center space-x-4">
          <a href="mailto:support@buildpassed.com">Contact</a>
          <a href="https://twitter.com/BuildPassed">Twitter</a>
          <a href="https://github.com/BuildPassed">GitHub</a>
        </footer>
      </div>
    </Route>
    <Route path="/redirect" component={Redirector} />
  </Router>;
}

export default App;